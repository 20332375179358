import React from 'react';
import { Image, Container } from 'semantic-ui-react';
import { AboutLayout } from '../components/Layout'
import styled from 'styled-components';
import img from '../../static/img/updated-why-2.svg';

const NotFoundPage = styled(({ className }) => (
  <AboutLayout>
    <Container className={className}>
      <div className="col-left">
        <Image src={img} alt="404 Not found" />
      </div>
      <div className="col-right">
        <div className="text-h1 txt-color-3">Uh oh!</div>
        <p className="text-h3">We couldn't find that page.</p>
        <p className="text-p">You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </Container>
  </AboutLayout>
))`
  .text-h1 {
    @media screen and (max-width: 600px) {
      font-size: 3.0em;
      line-height: 1.5;
    }
    @media screen and (max-width: 992px) {
      font-size: 4.0em;
      line-height: 1.2;
    }
    @media screen and (min-width: 993px) {
      font-size: 5.0em;
      line-height: 1.24;
    }

    margin-bottom: .714rem;
    font-weight: 900;
    color: #CD5B49;
  }

  .text-h3 {
    @media screen and (max-width: 600px) {
      font-size: 2.4em;
    }
    @media screen and (max-width: 992px) {
      font-size: 2.4em;
    }
    @media screen and (min-width: 993px) {
      font-size: 3.0em;
    }
    line-height: 1.3;
    margin-bottom: .714rem;
  }

  .text-p {
    @media screen and (max-width: 600px) {
      font-size: 1.4em;
    }
    @media screen and (min-width: 601px) {
      font-size: 1.6em;
    }
    line-height: 1.5;
    margin-bottom: .79rem;
  }

  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 60vh;
  margin-bottom: 25px;
  .col-left {
    flex:1;
    min-width: 320px;
  }
  .col-right {
    padding: 20px;
    min-width: 320px;
    flex:1;
    display: flex;
    flex-direction: column;
    align-item: center;
    justify-content: center;
  }
`;

export default NotFoundPage
